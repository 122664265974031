<template>
  <div >
    <div v-show="step==stepDocumentationMain" style="background-color:#FFF000;">
      <v-row>
        <v-col
          align="center"
          justify="center"
        >
          <h3 class="darkPurple">{{clientFullname}},<br>¡tu préstamo fue aprobado!</h3>
          <v-img
            id="imagePetGreet"
            src="@/assets/img/pet_photos.png"
            alt="Mascota saludando"
          >
          </v-img>
        </v-col>
      </v-row>
    </div>
    <div v-show="step==stepCheckData" style="background-color:#FFF000;">
      <v-row>
        <v-col
          align="center"
          justify="center"
        >
          <h3 class="darkPurple">Confirmá tu préstamo:</h3>
          <v-img
            id="imagePetOk"
            src="@/assets/img/pet_ok.png"
            alt="Mascota saludando"
          >
          </v-img>
        </v-col>
      </v-row>
    </div>
    <div v-show="step==stepFinishLoanRequest" style="background-color:#FFF000;">
      <v-row>
        <v-col
          align="center"
          justify="center"
        >
          <v-img
            id="imagePetCreditHappy"
            src="@/assets/img/pet_video_finished_request.gif"
            alt="logo"
          ></v-img>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "headerComponent",
  props: {
    step: { Number, default: 1},
    stepDocumentationMain: { Number, default: 4 },
    stepCheckData: {Number, default: 7},
    stepFinishLoanRequest: {Number, default: 8},
  },
  computed:{
    clientFullname() {
      return this.$store.getters.getFullname;
    }
  }
}
</script>
